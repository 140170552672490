// import { WhatsAppOutlined, LinkedinOutlined, FacebookOutlined, MailOutlined} from '@ant-design/icons'
import { FaArrowUp, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaWhatsapp } from 'react-icons/fa'
// FaFacebookF, FiYoutube, FiTwitter, FiFacebook, FiLinkedin
import { FiMail } from 'react-icons/fi'
import './footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
  const backToTop = () =>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <div className='footer'>
      <div className='footer-cont'>
        <div className='footer-cont1'>
        <div className='top' onClick={backToTop}> Back To Top <FaArrowUp/></div>
          <Link className='company_name' to='/'>Jain & Associates</Link>
          <div className='company_page-link'>
            <Link className='link' to='/portfolio'>Projects</Link>
            <Link className='link' to='/who-we-are'>Expertise</Link>
            <Link className='link' to='https://blogs.jain-associates.com/'>News</Link>
            <Link className='link' to='/what-we-do'>Careers</Link>
            <Link className='link' to='/contact'>Contact</Link>
          </div>
        </div>
        <div className='footer-cont2'>
        
          <div className='media-link'>
              <a target='__blank' href='mailto:info@jain-associates.com' aria-label="Mail me"><FiMail className='media'/></a>
              <a target='__blank'href='https://www.instagram.com/jain.aec' aria-label="Instagram"><FaInstagram className='media'/></a>
              <a target='__blank' href='https://in.linkedin.com/company/jain-and-associates-architects'aria-label="Linkedin"><FaLinkedinIn className='media'/></a>
              <a target='__blank' href='https://www.youtube.com/@greenqi8167' aria-label="Youtube"><FaYoutube className='media'/></a>
              {/* <a target='__blank'href='https://twitter.com/' aria-label="Twitter"><FaTwitter className='media'/></a> */}
              <a target='__blank' href='https://wa.me/+919625400199' aria-label="Whatsapp"><FaWhatsapp className='media'/></a>
          </div>
          
          <div className='policy'><div>Legal and policies &nbsp; </div> <div>© 2023 Jain & Associates. All Rights Reserved.</div></div>
        </div>
      </div> 
    </div>
  )
}

export default Footer

// fa 
// FaInstagram FaLinkedinIn FaYoutube FaTwitter FaWhatsapp FaFacebookF  mail