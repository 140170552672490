import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- AMBEDKAR MEMORIAL/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- AMBEDKAR MEMORIAL/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- AMBEDKAR MEMORIAL/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- AMBEDKAR MEMORIAL/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH- AMBEDKAR MEMORIAL/1-9.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
]


const AmbedkarMemorial = () => {
  return (
    <>
    <Navbar />
    <div className='project'>
      <Helmet>
        <title> Ambedkar Memorial </title>
        <meta 
          name='description'
          content="Design honours Indian context, climate, and Buddhist motifs, reflecting Babasaheb's beliefs. MMRDA sought global designs for Mumbai's Memorial."
        />
        <meta name="keywords" content="memorial architecture, 
          memorial architecture planning,
          theme based memorial architecture ,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi  ,
          architecture firm India " 
        />
        <meta name='title' content='Ambedkar Memorial' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
        <meta property="og:title" content="Ambedkar Memorial" />
          <meta property="og:description" content="Design honours Indian context, climate, and Buddhist motifs, reflecting Babasaheb's beliefs. MMRDA sought global designs for Mumbai's Memorial." />
      </Helmet>
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Babasaheb Ambedkar Memorial</div>
          <div className='project-add'>Mumbai, India</div>

          <div className='date-area'>
            <div className='date'>2013</div>
            <div className='area'>Site Area: 11.96 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 608.5 Million</div>
            <div className='client'>Client:  MMRDA, Mumbai</div>
            <div className='status'>Status: Proposal</div>
          </div>
          <div className='short-info'>
            Scope of Services: Conceptual Design
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The building’s design aligns with the subject’s context, Indian climate, and architectural style. The main Memorial structure 
                draws inspiration from the Buddhist stupa, incorporating architectural elements rooted in Buddhist motifs, openings, and adornments. <br/>This choice echoes Babasaheb’s ideology, as he embraced Buddhism later in life and promoted its adoption among the 
                Dalit community.
            </p>
            <p> The MMRDA had initiated a closed competitive bidding, inviting renowned international architectural design practices 
                to submit designs for the Babasaheb Ambedkar Memorial 
                in Mumbai.
            </p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Babasaheb Ambedkar Memorial Mumbai, India'/></div>
        </div>
      </div>
      <div className='gallery'>
        <div className='gallery-preview'>
          <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default AmbedkarMemorial;


