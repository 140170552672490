import { configureStore } from "@reduxjs/toolkit";
// import { createStore } from "redux";


function myReducer(state = initialState, action) {
    if (action.type === 'update_array') {
        const newState = {...state};
        newState.breadcrumbData = action.data;
        return newState;
    }
    if (action.type === 'update_navbarActive') {
        const newState = {...state};
        newState.navbarActive = action.navbarActive;
        return newState;
    }
    return state;
}

export const initialState = {
    breadcrumbData: [], 
    navbarActive: false 
}

const store = configureStore({
    reducer: myReducer
});

export default store;