import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-15.webp'
import image8 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-17.webp'
import image9 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-19.webp'
import image10 from '../../assets/LANDSCAPE/LANDS- TIGER SAFARI/1-21.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
  {
    id: 9,
    image:image9,
  },
  {
    id: 10,
    image:image10,
  },
]


const TigerSafari = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      <Helmet>
        <title> Ranthambhore Tiger Safari cum Rescue Centre </title>
        <meta 
          name='description'
          content="Proposed tiger safari & rescue center to ease tourism strain from the core forest in Ranthambore, Rajasthan"
        />
        <meta name="keywords" content="Zoo Architects,
          Rajasthan architecture firms,
          Safari Designer India,
          Zoo designers India,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi
          " 
        />
        <meta name='title' content='Ranthambhore Tiger Safari cum Rescue Centre' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Tiger Safari cum Rescue Centre</div>
          <div className='project-add'>Sawai Madhopur, Rajasthan, India</div>

          <div className='date-area'>
            <div className='date'>2017</div>
            <div className='area'>Site Area: 2250 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 4.8 Billion</div>
            <div className='client'>Client: CCF, Govt. of Rajasthan</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
            Scope of Services: : Detailed Project Report
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Ranthambore National Park is one of the biggest and most 
                renowned national park in Northern India. The park is located 
                in the Sawai Madhopur district of southeastern Rajasthan, 
                which is about 130 km from Jaipur. Being considered as one 
                of the famous and former hunting grounds of the Maharajas 
                of Jaipur, today the Ranthambore National Park terrain is major wildlife tourist attraction spot that has pulled the attention 
                of many wildlife photographers and lovers in this destination.</p>
            <p>A tiger safari cum Rescue centre is proposed in the buffer 
                area of the forest to divert the tourism pressure from the core 
                forest. It comprises of Day Safari, Night Safari and Rescue 
                Centre as its main programmatic intervention.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Tiger Safari cum Rescue Centre Sawai Madhopur, Rajasthan, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default TigerSafari;


