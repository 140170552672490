import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-15.webp'
import image8 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-17.webp'
import image9 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-19.webp'
import image10 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-21.webp'
import image11 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-23.webp'
import image12 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-25.webp'
import image13 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-27.webp'
import image14 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-29.webp'
import image15 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-31.webp'
import image16 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-33.webp'
import image17 from '../../assets/LANDSCAPE/LANDS- GOREWADA ZOO/1-35.webp'
import { Helmet } from 'react-helmet';




const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
  {
    id: 9,
    image:image9,
  },
  {
    id: 10,
    image:image10,
  },
  {
    id: 11,
    image:image11,
  },
  {
    id: 12,
    image:image12,
  },
  {
    id: 13,
    image:image13,
  },
  {
    id: 14,
    image:image14,
  },
  {
    id: 15,
    image:image15,
  },
  {
    id: 16,
    image:image16,
  },
  {
    id: 17,
    image:image17,
  }
]


const GorewadaZoo = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Gorewada Zoo and Safari Park </title>
        <meta 
          name='description'
          content='It provides best serviecs in the field of Architecture.'
        />
        <meta name="keywords" content="zoo designing and planning ,
          zoo architecture,
          zoo and safari park planning,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          landscape architects india
          " 
        />
        <meta name='title' content='Gorewada National Park offers diverse habitats, tribal villages, and wildlife encounters. Development optimizes existing resources.' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>International Zoo & Safari Park</div>
          <div className='project-add'>Gorewada, Nagpur, India</div>

          <div className='date-area'>
            <div className='date'>2018-19</div>
            <div className='area'>Site Area: 1394 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 5000 Millions</div>
            <div className='client'>Client: Bernard Harrison & Friends, Singapore. </div>
            <div className='status'>Status: Completed Phase 1</div>
          </div>
          <div className='short-info'>
            Scope of Services: Comprehensive Architectural Design Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Embarking on a captivating drive into the enchanting ‘Gondwana National Park,’ visitors are taken on a mythical journey 
                through diverse habitats, encountering indigenous tribal villages and wildlife. </p>
           <p>The park comprises three distinct sections: Indian Forest, Indian Desert, and Indian Grassland (Leopard Safari). </p>
           <p>Each section offers a unique experience, allowing visitors 
                to explore and appreciate the richness and diversity of the 
                park’s landscapes, local communities, and fascinating animal 
                inhabitants.The development project will make efficient use 
                of existing infrastructure, including roads, fences, buildings, 
                rescue center, and veterinary hospital, to minimize the need 
                for new constructions and optimize available resources
            </p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='International Zoo & Safari Park Gorewada, Nagpur, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default GorewadaZoo;


