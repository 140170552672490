import React from 'react'

import './App.css';
import { Provider } from 'react-redux';
import store from './components/context/myReducer';
import RoutePage from './components/routePage/RoutePage';




function App() {
  return (
    <Provider store={store}>
      <div className="App">
            <RoutePage/ >
      </div>
    </Provider>
  );
}

export default App;
