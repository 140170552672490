import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-5.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-15.webp'
import image8 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-17.webp'
import image9 from '../../assets/LANDSCAPE/LANDS- KOLWAN PARK/1-19.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
  {
    id: 9,
    image:image9,
  },
]


const KolwanPark = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Madhubhoomi </title>
        <meta 
          name='description'
          content='Swanubhooti Vatika’ links nature, self-reflection at Chinmaya Vibhooti, nurturing mind, body, and soul in diverse landscapes.'
        />
        <meta name="keywords" content="theme designing for park,
          architectural features for park designing,
          landscape features for park designing,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          landscape architect india "
        />
        <meta name='title' content='Madhubhoomi  CHARAVAI PARK' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Madhubhoomi Charavai Park</div>
          <div className='project-add'>Kolwan, Maharashtra, India</div>

          <div className='date-area'>
            <div className='date'>2014-15 </div>
            <div className='area'>Site Area: 7.2 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 98 Million</div>
            <div className='client'>Client: Chinmaya Mission Trust, Mumbai</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Comprehensive Landscape Design 
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The comprehensive landscape strategy for the enlightenment park, or vatika, establishes a profound link between 
                humans, nature, and awareness. “Swanubhooti Vatika” nurtures the mind, body, and soul, providing an environment for 
                self-reflection, understanding, and motivation.</p>
            <p>Standing in Chinmaya Vibhooti’s reception atrium, I felt drawn 
                to the immense Signature Om. Only a small courtyard and a 
                wall were visible. The wall gradually revealed itself, resembling a mountain rising to the skies. Walking through, I encountered messages and symbols, including figures like Sri 
                Adi Sankaracharya and Goswami Tulsidas. A bridge, Gyana 
                Setu, led to a panoramic vista. On one side, Soumya Kashi, 
                the Himalayan bird, perched on jagged peaks, and on the 
                other, Sri Hanuman ji poised for a leap. A central tree surrounded by diverse paths welcomed me to Madhubhoomi</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Madhubhoomi Charavai Park Kolwan, Maharashtra, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default KolwanPark;


