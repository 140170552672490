
import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './errorPage.scss'
const ErrorPage = () => {
  const navbarActive = false;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({type:"update_navbarActive", navbarActive})
  });

  return (
    <div className='errorPage'>
        <h1>404 &#128531;</h1>
        <h3>Page Not Found</h3>
        
        <Link className='link' to='..'><button> Homepage </button></Link> 
        {/* <a className='link' href='/'><button> Homepage </button></a> */}
        
    </div>
  )
}

export default ErrorPage