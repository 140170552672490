import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- LGBI AIRPORT/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- LGBI AIRPORT/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- LGBI AIRPORT/1-5.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
]


const LgbiAirport = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> LGBI Airport </title>
        <meta 
          name='description'
          content='The project involved multiple airports; a subcommittee appointed Jain & Associates as the Landscape Architect, accomplishing comprehensive work by 2009.'
        />
        <meta name="keywords" content="landscape design features for an airport,
          architecture firm in gurgaon ,
          architects guwahati,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India
          " 
        />
        <meta name='title' content='LGBI Airport' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Landscape Design of L.G.B.I. Airport</div>
          <div className='project-add'>Guwahati, Assam, India</div>

          <div className='date-area'>
            <div className='date'>2009</div>
            <div className='area'>Site Area: 5 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 56 Million</div>
            <div className='client'>Client: Airport Authority of India</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Canopy and Landscape Design
          </div>
          <div className='line'></div>

          <div className='description'>
             <p>The project involved multiple airports, including Guwahati, 
                Bhubaneshwar, Amritsar, and Pathankot, with a subcommittee appointed by the Chairman of AAI selecting Jain & Associates as the Landscape Architect.</p>
             <p>The undertaken tasks included designing one-way vehicular movement patterns, establishing efficient disembarking 
                zones, ensuring secure pedestrian pathways, incorporating 
                essential public facilities, implementing an effective signage 
                system, and integrating all necessary engineering services. 
                This comprehensive work was successfully accomplished by 
                2009.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Landscape Design of L.G.B.I. Airport Guwahati, Assam, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default LgbiAirport;


