import { Image } from 'antd';
import './project.scss'
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH-Guruvayur Temple Campus/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH-Guruvayur Temple Campus/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH-Guruvayur Temple Campus/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH-Guruvayur Temple Campus/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH-Guruvayur Temple Campus/1-9.webp'
import image5 from '../../assets/ARCHITECTURE/ARCH-Guruvayur Temple Campus/1-11.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
]


const Guruvayur = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Guruvayur Temple Campus </title>
        <meta 
          name='description'
          content="Guruvayur Temple plans Queue Complex for pilgrims, accommodating 8000 with AC waiting, public facilities, and a library."
        />
        <meta name="keywords" content="Guruvayur Temple architecture,
          architectural temple features,
          temple redevelopment ,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India
          " 
        />
        <meta name='title' content='Guruvayur Temple Campus' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Guruvayur Temple Campus</div>
          <div className='project-add'>Guruvayur, Kerala, India</div>

          <div className='date-area'>
            <div className='date'>2014</div>
            <div className='area'>Site Area: 3.1 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 849 Million</div>
            <div className='client'>Client:  GDMC, Kerala</div>
            <div className='status'>Status: Proposal</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Architectural Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Guruvayur Shri Krishna Temple, situated in Guruvayur, Kerala, is dedicated to Lord Krishna. This temple holds great 
                significance for Kerala’s Hindu community, drawing around 
                sixteen lakh pilgrims annually, particularly in December. In 
                response to this, the GDMC is planning a Queue Complex 
                for pilgrims, including queuing space for 8000 individuals, 
                air-conditioned waiting areas for 2000 people, public facilities, and a library with a reading room. </p>
            <p>This complex is set to be located within the Sathram Complex compound (Kousthubham Rest House and Sathyagrah 
                Smaraka Mandapam). The proposed plan entails the removal of the old structure adjacent to Nadapanthal. The allotted 
                land for this complex spans 0.90 acres</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Guruvayur Temple Campus, Kerala, India'/></div>
        </div>
      </div>
      
      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default Guruvayur;


