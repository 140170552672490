import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- DIU WATERBODY/1-15.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
]


const WaterBody = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Beautification of Water Bodies In Diu </title>
        <meta 
          name='description'
          content='Project targets water conservation, rural tourism promotion, and sustainable revenue generation for community development.'
        />
        <meta name="keywords" content="landscape architects india,
          beautification architecture,
          conservation of water bodies,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          conservation architect india
          " 
        />
        <meta name='title' content='Beautification of Water Bodies In Diu' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Beautification of Water bodies</div>
          <div className='project-add'>Diu, India</div>

          <div className='date-area'>
            <div className='date'>2017</div>
            <div className='area'>Site Area: 939.83 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 366.563 Millions</div>
            <div className='client'>Client:  Executive Engineer, Diu Panchayat</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Conservation & Landscape Services for Water Bodies
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The project’s aim and objectives encompass various facets. Firstly, it focuses on the conservation of natural water 
                resources, aiming to improve the ground water table, reduce 
                salinity ingress, and enhance moisture levels for improved 
                agriculture. <br/> Secondly, the initiative seeks to promote rural tourism by rectifying imbalances and utilizing natural assets to create attractive tourist destinations, thereby generating employment 
                opportunities. </p>
           <p> Lastly, a critical goal involves revenue generation through sustainable and strategic means</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Beautification of Water bodies DIU India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default WaterBody;


