import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- SIKKIM TENDONG ECOTOURISM/1-13.webp'
import { Helmet } from 'react-helmet';




const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
]


const SikkimTendong = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
     <Helmet>
        <title> Tendong Biodiversity Park  </title>
        <meta 
          name='description'
          content='FEWMD plans a top-notch Biodiversity Park & Ecotourism Centre in Tendong, Sikkim, showcasing biodiversity and promoting environmental awareness.'
        />
        <meta name="keywords" content="Ecotourism  architecture,
          landscape architects india,
          Biodiversity Park ,
          Sikkim Architecture	,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi
          " 
        />
        <meta name='title' content='BIODIVERSITY CUM ECOTOURISM CENTRE, SIKKIM' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Biodiversity cum Ecotourism Centre</div>
          <div className='project-add'>Tendong, Sikkim, India</div>

          <div className='date-area'>
            <div className='date'>2018</div>
            <div className='area'>Site Area: 4650 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 800 Million</div>
            <div className='client'>Client: FEWMD, Government of Sikkim</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
            Scope of Services: Detailed Project Report
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The Forest, Environment and Wildlife Management Department (FEWMD), Government of Sikkim intends to develop 
                a world class Biodiversity Park and Ecotourism Centre at a 
                designated area in Tendong, South District of Sikkim. This 
                facility is being established with an objective of protection, 
                conservation, propagation and extension of the state’s genetic resources and to utilise the facility for environmental 
                awareness and recreation. </p>
            <p>Components of the project would include display of the 
                State’s biodiversity in their natural state by setting out natural 
                areas with appropriate demarcation, signage and walkways, 
                international arboretum, series of mini interpretation centres 
                and adventure activies</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Biodiversity cum Ecotourism Centre Tendong, Sikkim, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default SikkimTendong;


