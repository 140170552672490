import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-15.webp'
import image8 from '../../assets/LANDSCAPE/LANDS- THRISSUR ZOO/1-17.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  }
]


const ThrissurZoo = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Thrissur Zoological Park </title>
        <meta 
          name='description'
          content='Zoo showcases animal themes; terraced gardens, rainforest & grassland zones immerse visitors in diverse habitats.'
        />
        <meta name="keywords" content="zoo design ,
          zoo architects,
          thrissur zoo ,
          zoo designers,
          zoo planning architects in delhi ncr,
          landscape architects india,
          thrissur zoo 
          " 
        />
        <meta name='title' content='Thrissur Zoological Park' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Thrissur Zoological Park</div>
          <div className='project-add'>Puthur, Thrissur, India</div>

          <div className='date-area'>
            <div className='date'>2020</div>
            <div className='area'>Site Area: 84 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 168.93 Million</div>
            <div className='client'>Client: DCF & Special officer, Thrissur Zoological Park</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: : DPR for Landscaping & Horticulture Works with Tender Documents and Site Supervision
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The zoo’s entrance boasts animal-themed features like 
                grillwork, bamboo plantations, and an elephant fountain. 
                Terraced flower gardens, courtyards with water cascades, 
                and distinct entrance markers enhance the experience. The 
                Central India section replicates deciduous forests, while the 
                Rainforest zone embodies Western Ghats rainforests. </p>
            <p>African Grasslands and Wetlands area includes Kopje rocks 
                and Ndebele-inspired structures. Mountain Grasslands are 
                marked by “Mangrove Palms” and offer flower gardens, spice 
                gardens, and educational crop fields.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Thrissur Zoological Park Puthur, Thrissur, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default ThrissurZoo;


