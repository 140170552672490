import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- REGIONAL PARK DEV/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- REGIONAL PARK DEV/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- REGIONAL PARK DEV/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- REGIONAL PARK DEV/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- REGIONAL PARK DEV/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- REGIONAL PARK DEV/1-11.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
]


const RegionalPark = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Riverfront Development  </title>
        <meta 
          name='description'
          content="Scope includes landscape, architectural, and service design. Site is 2825m along Hindon River, bordered by an embankment and planned residential area."
        />
        <meta name="keywords" content="landscape architects india,
          ghaziabad riverfront,
          ghaziabad architecture,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India
          " 
        />
        <meta name='title' content='REGIONAL PARKS DEVELOPMENT, GHAZIABAD' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Regional Parks Development</div>
          <div className='project-add'>Ghaziabad, Uttar Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2014-15</div>
            <div className='area'>Site Area: 106.09 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 558.8 Million</div>
            <div className='client'>Client: Ghaziabad Development Authority</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Landscape Design Consultancy, associated Architectural works with Services design
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The site stretches for 2825 meters along the eastern side 
                of the Hindon river. On the western side, it is bordered by a 
                recently constructed embankment, while a planned residential area forms the boundary on the eastern side. Positioned 
                between NH24 Bypass and Hindon Dam (Pusta Bridge), the 
                site can be accessed via a public road at the edge of the 
                planned residential area. </p>
            <p>Although reaching it from the newly constructed embankment is more challenging. Our scope entails a detailed design consultancy encompassing Landscape and Architectural related works, including services design</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Regional Parks Development Ghaziabad, Uttar Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
            {
                projectImages.map((data, index) => (
                    <Image 
                        className='gallery-content'
                        width={250}
                        src={data.image}
                        key={index}
                    />
                ))
            }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default RegionalPark;


