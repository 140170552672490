import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- MEDIDATION CENTER/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- MEDIDATION CENTER/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- MEDIDATION CENTER/1-5.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
]


const MedidationCenter = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> International Meditation Centre </title>
        <meta 
          name='description'
          content="IMC offers residential facilities for Preksha Meditation with offices, halls, dining, guest areas, and landscapes for holistic development. "
        />
        <meta name="keywords" content="meditation center architecture,
          planning meditation center,
          architecture firm in gurgaon,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          preksha meditation " 
        />
        <meta name='title' content='International Meditation Centre' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>International Meditation Centre</div>
          <div className='project-add'>Ladnun, Rajasthan, India</div>

          <div className='date-area'>
            <div className='date'>2005</div>
            <div className='area'>Site Area: 9 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 82 Million</div>
            <div className='client'>Client: Jain Terapanth Samaj </div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Comprehnsive Architecture Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>International Meditation Centre (IMC) is conceived as a stand 
                alone residential centre providing all facilities to ‘Sadhaks’ 
                to enable them to understand Preksha Meditation coarse at 
                various levels.</p>
            <p>There were various components of development such as 
                Office, Meditation hall, Lecture room, Dining room, Guest 
                house, Living room, Kitchen and various Landscape related 
                components as well.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='International Meditation Centre Ladnun, Rajasthan, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default MedidationCenter;


