import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- HISAR PONDS/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- HISAR PONDS/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- HISAR PONDS/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- HISAR PONDS/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- HISAR PONDS/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- HISAR PONDS/1-11.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
]


const HisarPond = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Beautification of Water Bodies In Hisar </title>
        <meta 
          name='description'
          content='Project goals: water conservation, rural tourism boost, employment, and sustainable revenue generation.'
        />
        <meta name="keywords" content="landscape architects india,
          conservation architect india,
          conservation of water bodies,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India
          " />
        <meta name='title' content='Beautification of Water Bodies, Hisar, Haryana' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Beautification of Water Bodies</div>
          <div className='project-add'>Hisar, Haryana, India</div>

          <div className='date-area'>
            <div className='date'>2022</div>
            <div className='area'>Site Area: 22.82 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 212 Million</div>
            <div className='client'>Client: Hisar Municipal Corporation</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
                Detailed Project Report & Landscape Services
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The project’s objectives cover several aspects. It aims to 
                conserve natural water resources by enhancing groundwater 
                tables, reducing salinity ingress, and promoting better agriculture moisture levels.</p>
           <p>Additionally, the project strives to boost rural tourism, create 
                balanced and appealing tourist sites, and generate employment opportunities. A key objective is sustainable revenue 
                generation through strategic methods</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Beautification of Water Bodies Hisar, Haryana, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default HisarPond;


