import { Image } from 'antd';
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';

import projectImg from '../../assets/ARCHITECTURE/ARCH - Rajaswa Bhawan, New Delhi/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH - Rajaswa Bhawan, New Delhi/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH - Rajaswa Bhawan, New Delhi/1-3.webp'
import { Helmet } from 'react-helmet';

const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
]


const RajaswaBhawan = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Rajaswa Bhawan </title>
        <meta 
          name='description'
          content="The Ministry  of Finance plans Rajaswa Bhawan, National Tax HQ in Delhi's Lutyens area. Nationwide design comp held, DRDO leads project, cost around Rs. 175 Cr."
        />
        <meta name="keywords" content="Rajaswa Bhawan, Rajaswa Bhawan architecture, Rajaswa Bhawan building, architecture firm in gurgaon, architects in gurgaon, architects in delhi, architecture firm in delhi, architecture firm India" />
        <meta name='title' content='Rajaswa Bhawan' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Rajaswa Bhawan</div>
          <div className='project-add'>New Delhi, India</div>

          <div className='date-area'>
            <div className='date'>2010</div>
            <div className='area'>Site Area: 5.65 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR Million</div>
            <div className='client'>Client: DRDO, New Delhi</div>
            <div className='status'>Status: Proposal</div>
          </div>
          <div className='short-info'>
            Scope of Services: Complete Comprehnsive Architecture 
            Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The Ministry of Finance’s Department of Revenue intends to 
            build Rajaswa Bhawan, the National Tax Headquarters, on 
            a prominent 5.65-acre plot along Kasturba Gandhi Marg in 
            New Delhi’s Lutyen’s area.</p>
           <p> To select the finest architectural design, a nationwide open 
            Design Competition was conducted. The implementation of 
            the project was undertaken by DRDO on behalf of the Department of Revenue. The estimated project cost is approximately Rs. 175 Crores.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Rajaswa Bhawan New Delhi, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default RajaswaBhawan;


