import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { lazy} from 'react'
import ScrollToTop from '../error/ScrollToTop';



// import Home from '../homepage/Home';
// import Portfolio from '../portfolio/Portfolio';
// import About from '../about/About';
// import Leadership from '../leadership/Leadership';
// import PortfolioPage from '../portfolio/PortfolioPage';
// import Project from '../project/Project';
// import Services from '../services/Services';
// import ContactPage from '../contactpage/ContactPage';
// import BimServices from '../serviceComp/BimServices';
// import DesignProcess from '../designProcess/DesignProcess';

import ErrorPage from '../error/ErrorPage';
import Loading from '../error/Loading';

import { AmbedkarMemorial, BabaBaidyanath, Baroda, Guruvayur, Kavaratti, 
    MataChintpurniTemple, MedidationCenter, MuncipalCorp, PcmcZoo, 
    RajaswaBhawan, Secretariat,ShoppingComplex, SsbGhitorni  } from '../project';

import { AnubhutiVatika, CrocoEduCenter, DelhiZoo, WaterBody, GorewadaZoo, 
    HisarPond, KangraZoo, KolwanPark, LgbiAirport, PayakapuramLake, 
    RegionalPark, SheelKunj, SikkimTendong, SsbSrinagar, ThrissurZoo,
    TigerSafari, VizagZoo, WoodsResort, Gmda,  } from '../project'
 




// import ThreeDModelling from '../serviceComp/ThreeDModelling';
// import FourDConstruction from '../serviceComp/FourDConstruction';
// import StructuralDesign from '../serviceComp/StructuralDesign';
// import ClashDetection from '../serviceComp/ClashDetection';
// import ScantoBim2 from '../serviceComp/ScantoBim2';
// import EnergyModelling from '../serviceComp/EnergyModelling';
// import QuantityTakeoff from '../serviceComp/QuantityTakeoff';
// import ZooDesign from '../serviceComp/ZooDesign';
// import MEPModelling from '../serviceComp/MEPModelling';
// import LandscapeArchi from '../serviceComp/LandscapeArchi';
// import ArchitectureConsult from '../serviceComp/ArchitectureConsult';
// import UrbanPlanning from '../serviceComp/UrbanPlanning';
// import LeedCertification from '../serviceComp/LeedCertification';
// import Architects from '../services/Architects';
// import StructuralEngineers from '../services/StructuralEngineers';
// import GeneralContractors from '../services/GeneralContractors';
// import MEPEngineers from '../services/MEPEngineers';
// import SubContractor from '../services/SubContractor';
// import DrywallContractor from '../services/DrywallContractor';
// import BuildingOwners from '../services/BuildingOwners';
// import DaylightModelling from '../serviceComp/DaylightModelling';
// import GrihaCertification from '../serviceComp/GrihaCertification';
// import WellCertification from '../serviceComp/WellCertification';


const Home = lazy(() => import('../homepage/Home'));
// const Project = lazy(() => import('../project/Project'));
const BimServices = lazy(() => import('../serviceComp/BimServices'));
const DesignProcess = lazy(() => import('../designProcess/DesignProcess'));

const About = lazy(() => import('../about/About'));
const Services = lazy(() => import('../services/Services'));
const ContactPage = lazy(() => import('../contactpage/ContactPage'));


const ThreeDModelling = lazy(() => import('../serviceComp/ThreeDModelling'));
const FourDConstruction = lazy(() => import('../serviceComp/FourDConstruction'));
const StructuralDesign = lazy(() => import('../serviceComp/StructuralDesign'));
const ClashDetection = lazy(() => import('../serviceComp/ClashDetection'));
const ScantoBim2 = lazy(() => import('../serviceComp/ScantoBim2'));
const EnergyModelling = lazy(() => import('../serviceComp/EnergyModelling'));
const QuantityTakeoff = lazy(() => import('../serviceComp/QuantityTakeoff'));
const ZooDesign = lazy(() => import('../serviceComp/ZooDesign'));
const MEPModelling = lazy(() => import('../serviceComp/MEPModelling'));
const LandscapeArchi = lazy(() => import('../serviceComp/LandscapeArchi'));
const ArchitectureConsult = lazy(() => import('../serviceComp/ArchitectureConsult'));
const UrbanPlanning = lazy(() => import('../serviceComp/UrbanPlanning'));
const LeedCertification = lazy(() => import('../serviceComp/LeedCertification'));
const Architects = lazy(() => import('../services/Architects'));
const StructuralEngineers = lazy(() => import('../services/StructuralEngineers'));
const MEPEngineers = lazy(() => import('../services/MEPEngineers'));
const SubContractor = lazy(() => import('../services/SubContractor'));
const DrywallContractor = lazy(() => import('../services/DrywallContractor'));
const BuildingOwners = lazy(() => import('../services/BuildingOwners'));
const DaylightModelling = lazy(() => import('../serviceComp/DaylightModelling'));
const GrihaCertification = lazy(() => import('../serviceComp/GrihaCertification'));
const WellCertification = lazy(() => import('../serviceComp/WellCertification'));


const ScantoBim = lazy(() => import('../serviceComp/ScantoBim'));
const PortfolioPage = lazy(() => import('../portfolio/PortfolioPage'));
const MeghalayaEcotourism = lazy(() => import('../project/MeghalayaEcotourism'));
const VallabhSmarak = lazy(() => import('../project/VallabhSmarak')); //Archi
const ShopDrawing = lazy(() => import('../serviceComp/ShopDrawing'));
const GeneralContractors = lazy(() => import('../services/GeneralContractors'));





const RoutePage = () => {
  return (
    <><BrowserRouter>
    <Routes>
        <Route
            path="/"
            element={ <React.Suspense fallback={<Loading />} >
            <Home />
        </React.Suspense>
         }
        />      
        <Route
            path="/what-we-do"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Services />
                </React.Suspense>
                }
        />           

        <Route
            path="/services"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Services />
                </React.Suspense>
            }
        />



        {/* <Route
            path="/project"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Project />
                </React.Suspense>
            }
        /> */}
        {/* Architecture  */}
        <Route
            path="/portfolio/RESIDENTIAL-COMPLEX-FOR-MUNICIPAL-CORPORATION-SEC-41-GURGAON"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <MuncipalCorp />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/rajaswa-bhawan-newdelhi.html"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <RajaswaBhawan />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/ambedkar-memorial"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <AmbedkarMemorial />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/baroda-complex"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Baroda />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/civil-secretariat-arunachal-pradesh"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Secretariat />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/mata-chintpurni-temple"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <MataChintpurniTemple />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/meditation-center"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <MedidationCenter />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/pcmc-zoo"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <PcmcZoo />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/residential-complex-kavaratti-lakshadweep"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Kavaratti />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/residential-complex-gurgaon"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <MuncipalCorp />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/ssb-ghitorni"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <SsbGhitorni />
                </React.Suspense>
            }
        />
        
        {/* <Route
            path="/portfolio/vallabh-smarak"
            element={ <VallabhSmarak /> }
        /> */}
        <Route
            path="/portfolio/vallabh-smarak"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <VallabhSmarak />
                </React.Suspense> 
            }
        />
        
        <Route
            path="/portfolio/baba-baidyanath-campus"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <BabaBaidyanath />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/guruvayur-temple-campus"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Guruvayur />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/shopping-complex-jammu"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <ShoppingComplex />
                </React.Suspense>
            }
        />

        {/* Landscape  */}

        <Route
            path="/portfolio/anubhooti-vatika"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <AnubhutiVatika />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/crocodile-edu-center"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <CrocoEduCenter />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/delhi-zoo"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <DelhiZoo />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/diu-waterbody"
            element={
                <React.Suspense fallback={<Loading />} >
                    <WaterBody />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/gorewada-zoo"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <GorewadaZoo />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/hisar-ponds"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <HisarPond />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/kangra-zoo"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <KangraZoo />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/kolwan-park"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <KolwanPark />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/lgbi-airport"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <LgbiAirport />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/payakapuram-lake"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <PayakapuramLake />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/regional-park-dev"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <RegionalPark />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/sheel-kunj"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <SheelKunj />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/sikkim-tendong-ecotourism"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <SikkimTendong />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/ssb-srinagar"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <SsbSrinagar />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/thrissur-zoo"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <ThrissurZoo />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/tiger-safari"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <TigerSafari />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/vizag-zoo"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <VizagZoo />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/woods-resort"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <WoodsResort />
                </React.Suspense>
            }
        />

        {/* Urbanism  */}

        <Route
            path="/portfolio/gmda"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Gmda />
                </React.Suspense>
            }
        />
        <Route
            path="/portfolio/meghalaya-ecotourism"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <MeghalayaEcotourism />
                </React.Suspense> 
            }
        />
        

        {/* <Route
            path="/project"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Project />
                </React.Suspense>
            }
        /> */}
        






        <Route
            path="/portfolio"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <PortfolioPage />
                </React.Suspense> 
            }
        />
        <Route
            path="/PortfolioPage"
            element={ 
                <React.Suspense fallback={<Loading  />} >
                    <PortfolioPage />
                </React.Suspense> 
            }
        />
        <Route
            path="/contact"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <ContactPage />
                </React.Suspense> 
            }
        />

        {/* ************************************************* */}
        <Route
            path="/bimservices"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <BimServices />
                </React.Suspense>
            }
        /> 

        <Route
            path="/bimservices/3D-Modelling"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <ThreeDModelling />
                </React.Suspense>
            }
        /> 
        <Route
            path="/bimservices/4D-Construction"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <FourDConstruction />
                </React.Suspense>
            }
        /> 
        <Route
            path="/bimservices/Structural-Design"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <StructuralDesign />
                </React.Suspense>
            }
        /> 
        <Route
            path="/bimservices/clash-detection"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <ClashDetection />
                </React.Suspense>
            }
        /> 

        <Route
            path="/bimservices/scan-to-bim"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <ScantoBim2 />
            </React.Suspense> 
            }
        />         
        <Route
            path="/bimservices/Quantity-Takeoff"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <QuantityTakeoff />
            </React.Suspense> 
            }
        /> 
        <Route
            path="/bimservices/MEP-Modelling"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <MEPModelling />
            </React.Suspense> 
            }
        />
        <Route
            path="/bimservices/shop-drawing-production"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <ShopDrawing />
            </React.Suspense> 
            }
        />


        {/* under Architcture and Urbanism change mid url on both side  */}
        <Route
            path="/bimservices/safari-design"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <ZooDesign />
            </React.Suspense> 
            }
        />

        <Route
            path="/bimservices/landscape-architecture"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <LandscapeArchi />
            </React.Suspense> 
            }
        />
        <Route
            path="/bimservices/architecture-consultancy"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <ArchitectureConsult />
            </React.Suspense> 
            }
        />
        <Route
            path="/bimservices/urban-planning"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <UrbanPlanning />
            </React.Suspense> 
            }
        />


        {/* Under Sustainibility  */} 

        <Route
            path="/sustainibility/daylight-modelling"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <DaylightModelling />
            </React.Suspense> 
            }
        />
        <Route
            path="/sustainibility/energy-modelling"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <EnergyModelling />
            </React.Suspense> 
            }
        />
        {/* <Route
            path="/bimservices/leed-certification"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <LeedCertification />
            </React.Suspense> 
            }
        /> */}

        <Route
            path="/sustainibility/leed-certification"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <LeedCertification />
            </React.Suspense> 
            }
        />
        <Route
            path="/sustainibility/griha-certification"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <GrihaCertification />
            </React.Suspense> 
            }
        />
        <Route
            path="/sustainibility/well-certification"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <WellCertification />
            </React.Suspense> 
            }
        />

        {/* Under Verticals  */} 

        <Route
            path="/verticals/architects"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <Architects />
            </React.Suspense> 
            }
        /> 
        <Route
            path="/verticals/structural-engineer"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <StructuralEngineers />
            </React.Suspense> 
            }
        />
        <Route
            path="/verticals/MEP-engineer"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <MEPEngineers />
            </React.Suspense> 
            }
        />
        <Route
            path="/verticals/general-contractors"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <GeneralContractors />
            </React.Suspense> 
            }
        /> 
        <Route
            path="/verticals/sub-contractors"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <SubContractor />
            </React.Suspense> 
            }
        />
        <Route
            path="/verticals/dry-wall-contractors"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <DrywallContractor />
            </React.Suspense> 
            }
        />
        <Route
            path="/verticals/owners-and-building"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <BuildingOwners />
            </React.Suspense> 
            }
        />

        <Route
            path="/bimservices"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <BimServices />
                </React.Suspense>
            }
        /> 



        {/* ******************************************************** */}
        <Route
            path="/design-process"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <DesignProcess />
                </React.Suspense>
            }
        />    
        {/* <Route
            path="/leadership"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <Leadership />
                </React.Suspense>
            }
        />   */}
        <Route
            path="/who-we-are"
            element={ 
                <React.Suspense fallback={<Loading />} >
                    <About />
                </React.Suspense> 
                }
        />  

        <Route
            path="/scan-to-bim"
            element={ 
            <React.Suspense fallback={<Loading />} >
                <ScantoBim />
            </React.Suspense> 
            }
        />   

        {/* <Route
            path="/loading"
            element={ <Loading data = "Loading page."/> }
        />  */}
        <Route
            path="*"
            element={ <ErrorPage /> }
        />
    
    </Routes>
    <ScrollToTop />
    </BrowserRouter>
    </>

  )
}

export default RoutePage