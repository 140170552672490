import { Helmet } from 'react-helmet'
import './loading.scss'

const Loading = (props) => {
  return (
    <div className='loading'>

        <Helmet>
          <meta 
            name='description'
            content='Jain and Associates is an Architecture Engineering and Planning consulting organization that has delivered projects all over India in the past 30 years.'
          />
          <meta name="keywords" content="  Architecture firms, Landscape Architects, Zoo Designers, BIM Consultant, Safari Designer, Zoo Architect, Architecture projects " />
          <link rel="canonical" href="http://jain-associates.com/who-we-are" />
        </Helmet>


        <h1> {(props.data)? props.data : ""} </h1>
        <div className='cont-blink'>
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    </div>
  )
}

export default Loading