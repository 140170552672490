import { Link } from 'react-router-dom';
import'./footerUp.scss'

const FooterUp = () => {
  return (
    <div className='footerUp'>
        <div className='footerUp-box1'>
            <h1>Lets Build <br className='change-l'/>Responsibly</h1>
            <Link className='link' to='/contact'><button>Connect With Us</button></Link>
        </div>
        <div className='line'></div>
        <div className='footerUp-box2'>
            <h2>Find out more</h2>
            <Link className='link' to='/what-we-do'><button>What we do &rarr;</button><br/></Link>
            <Link className='link' to='/portfolio'><button>Our Portfolio &rarr;</button></Link>
        </div>
    </div>
  )
}

export default FooterUp