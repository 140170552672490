import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-1 rectangle.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-15.webp'
import image8 from '../../assets/LANDSCAPE/LANDS- ANUBHOOTI VATIKA/1-17.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
]



const AnubhutiVatika = () => {
  return (
    <><Navbar />
    <div className='project'>

      <Helmet>
        <title> Development of Avilala Ecological Park </title>
        <meta 
          name='description'
          content='Avilala Ecological Park near Venkateshwara Temple transforms into Sri Venkateshwara Anoobhuti Vatika for spiritual introspection.'
        />
        <meta name="keywords" content="Ecological Park architecture,
          Avilala Park,
          Landscape architects,
          architecture firm in gurgaon, 
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi
          " 
        />
        <meta name='title' content="Development of Avilala Ecological Park" />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
        <meta property="og:title" content="Development of Avilala Ecological Park" />
          <meta property="og:description" content="Avilala Ecological Park near Venkateshwara Temple transforms into Sri Venkateshwara Anoobhuti Vatika for spiritual introspection." />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Sri Venkateswara Anubhooti Vatika</div>
          <div className='project-add'>Tirupati, Andhra Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2017</div>
            <div className='area'>Site Area: 150 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 1170 Millions</div>
            <div className='client'>Client: Tirumala Tirupati Devasthanams (TTD Trust)</div>
            <div className='status'>Status: Proposal</div>
          </div>
          <div className='short-info'>
            Scope of Services: Comprehensive Architectural Design Consultancy 
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Avilala Ecological Park attracts pilgrims visiting Lord Venkateshwara Temple. Thus, the park’s vision extends beyond 
                ecological aspects and aims to incorporate a concept connected to the spiritual aspirations of the pilgrims.
                <br/>Our ambition was to create an environment where visitors 
                    can embark on an introspective journey to discover the divine 
                    presence within themselves. Hence, the park then named 
                    ‘Sri Venkateshwara Anoobhuti Vatika,’ inviting visitors on a 
                    journey of self-discovery and introspection. 
            </p>
           <p> The park’s concept goes beyond its ecological aspect, embracing a higher purpose of facilitating a transformative and 
                meaningful experience for every ordinary individual seeking 
                spiritual enlightenment.
            </p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Sri Venkateswara Anubhooti Vatika Tirupati, Andhra Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default AnubhutiVatika;


