import { Image } from 'antd';
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- Residential Complex at Kavaratti, Lakshadweep/1-1 rectangle.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- Residential Complex at Kavaratti, Lakshadweep/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- Residential Complex at Kavaratti, Lakshadweep/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- Residential Complex at Kavaratti, Lakshadweep/1-7.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
]


const Kavaratti = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Kavaratti Island Residential Complex </title>
        <meta 
          name='description'
          content='Design integrates sustainability with eco-friendly features, vernacular architecture for cultural harmony, and rapid construction methods.'
        />
        <meta name="keywords" content="react, helmet, seo" />
        <meta name='title' content='Kavaratti Island Residential Complex' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Residential Complex</div>
          <div className='project-add'>Kavaratti, Lakshadweep, India</div>

          <div className='date-area'>
            <div className='date'>2017</div>
            <div className='area'>Site Area: 2.57 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 400 Million</div>
            <div className='client'>Client: Public Works Department, Lakshwadeep</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Concept Development
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The design aims for sustainability with eco-friendly features 
                like fly-ash bricks, solar energy, waste recycling, and vermicomposting. It includes STP and Rainwater Harvesting. 
                Pre-engineered structural members speed up construction. </p>
            <p>Vernacular architectural vocabulary adds cultural identity, 
                harmonizing modernity with local heritage. </p>
            <p>The project becomes environmentally responsible, aesthetically pleasing, and culturally significant through these integrated elements.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Residential Complex Kavaratti, Lakshadweep, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                projectImages.map((data, index) => (
                  <Image 
                      className='gallery-content'
                      width={250}
                      src={data.image}
                      key={index}
                  />
                ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default Kavaratti;


