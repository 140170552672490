import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-9.webp'
import image5 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-11.webp'
import image6 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-13.webp'
import image7 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-15.webp'
import image8 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-17.webp'
import image9 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-19.webp'
import image10 from '../../assets/LANDSCAPE/LANDS- CROCODILE EDU CENTER/1-21.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
  {
    id: 9,
    image:image9,
  },
  {
    id: 10,
    image:image10,
  }
]


const CrocoEduCenter = () => {
  return (
    <><Navbar />
    <div className='project'>

      
      
      <Helmet>
        <title> Crocodile Education Center and Garden </title>
        <meta 
          name='description'
          content="Vadodara's Crocodile Center educates on ecosystems, preserving Mugger crocodiles and urban wildlife habitats along Vishwamitri River."
        />
        <meta name="keywords" content="vadodara crocodile park,
          zoo designers architects,
          landscape architects ,
          architecture firm in gurgaon, 
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi
          " 
        />
        <meta name='title' content='CROCODILE EDUCATION CENTER AND GARDEN' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Crocodile Education Center and Garden</div>
          <div className='project-add'>Vadodra, Gujarat, India</div>

          <div className='date-area'>
            <div className='date'>2017</div>
            <div className='area'>Site Area: 67 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 450 Millions</div>
            <div className='client'>Client:  Vadodara Municipal Corporation</div>
            <div className='status'>Status: DPR Submitted</div>
          </div>
          <div className='short-info'>
            Scope of Services: Detailed Project Report & Project Management Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The “Vishwamitri Crocodile Education Center and Garden” 
                by Vadodara Municipal Corporation safeguards riverside 
                flora and fauna, mitigating human-animal conflicts. Spanning 66.02 acres behind Rajmahal, it educates about ecosystems, emphasizing crocodiles’ unique relationships. The 
                center highlights adaptive behavior, nutrition, reproduction, 
                and evolution. It also showcases the impact of human activities on the environment. </p>
           <p> The project aims to preserve urban Mugger crocodiles and 
                existing urban wildlife habitats along the Vishwamitri River.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Crocodile Education Center and Garden Vadodra, Gujarat, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
          <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default CrocoEduCenter;


