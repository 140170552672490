import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- Mata Chintpurni Temple/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- Mata Chintpurni Temple/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- Mata Chintpurni Temple/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- Mata Chintpurni Temple/1-7.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
]


const MataChintpurniTemple = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Mata Chintpurni Temple </title>
        <meta 
          name='description'
          content="Project envisions temple promotion, visitor-friendly experience, eco-consciousness, tourism integration for spiritual significance and sustainability."
        />
        <meta name="keywords" content="Mata Chintpurni Temple,
          architectural temple features,
          temple redevelopment ,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi
          architecture firm in delhi,
          temple architecture " 
          />,
        <meta name='title' content='Mata Chintpurni Temple' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Redevelopment of Chintpurni Temple</div>
          <div className='project-add'>Una, Himachal Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2004</div>
            <div className='area'>Site Area: Town Area</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 310.83 Million</div>
            <div className='client'>Client: D.C. cum Temple Commissioner, Una (H.P.)</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: : Masterplanning and Detailed Project Report (DPR)
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The vision for the project is multifaceted, aiming to promote 
                the temple and create a clean, safe, and hassle-free experience for visitors.</p>
            <p>Additionally, the project seeks to integrate with regional 
                tourist circuits, thereby enhancing tourism in the area. An 
                essential aspect of the vision is to foster environment-conscious development and implement eco-friendly processes 
                throughout the project. </p>
            <p>By encompassing these objectives, the project aspires to 
                become a beacon of spiritual significance, a sought-after 
                destination for tourists, and a model of sustainable and responsible development that preserves and celebrates the 
                surrounding natural environment</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Redevelopment of Chintpurni Temple Una, Himachal Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default MataChintpurniTemple;


