import { Image } from 'antd';
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- SSB GHITORNI/1-1 RECTANGLE.webp'
// import image1 from '../../assets/ARCHITECTURE/ARCH- SSB GHITORNI/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- SSB GHITORNI/1-5.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  // {
  //   id: 1,
  //   image:image1,
  // },
  {
    id: 2,
    image:image2,
  },
]


const SsbGhitorni = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Sashastra SeemaBal (SSB) Campus </title>
        <meta 
          name='description'
          content="Ghitorni's 25th Battalion elevates VIP Campus for national events. Enhanced infrastructure merges aesthetics, function, and sustainability."
        />
        <meta name="keywords" content="SASHASTRA SEEMA BAL (SSB) CAMPUS, ssb ,ssb ghitorni, new delhi" />
        <meta name='title' content='SASHASTRA SEEMA BAL (SSB) CAMPUS, GHITORNI, NEW DELHI' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio/ssb-ghitorni" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Sashastra SeemaBal (SSB) Campus</div>
          <div className='project-add'>Ghitorni, New Delhi, India</div>

          <div className='date-area'>
            <div className='date'>2007</div>
            <div className='area'>Site Area: 20 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 120 Million</div>
            <div className='client'>Client: DG, SSB, Ministry of Home Affairs.</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Masterplanning, Architectural design and Landscape design
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The 25th Battalion in Ghitorni serves as an esteemed VIP 
                Campus, hosting national and regional events. </p>
            <p>Consequently, there was a need to enhance the infrastructure both aesthetically and functionally to align with its prestigious status. </p>
            <p>The scope of work encompasses various essential aspects, 
                including architectural design, urban design, landscape design, and water management alongside public health infrastructure. Through these integrated efforts, we aim to create 
                a harmonious and sustainable environment that caters to diverse aspects of the project, ensuring both aesthetic appeal 
                and functional efficiency.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Sashastra SeemaBal (SSB) Campus Ghitorni, New Delhi, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default SsbGhitorni;


