import { Image } from 'antd';
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH-Baba Baidyanath Campus/1-1.1 rectangle.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH-Baba Baidyanath Campus/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH-Baba Baidyanath Campus/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH-Baba Baidyanath Campus/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH-Baba Baidyanath Campus/1-9.webp'
import image5 from '../../assets/ARCHITECTURE/ARCH-Baba Baidyanath Campus/1-11.webp'
import { Helmet } from 'react-helmet';

const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
]


const BabaBaidyanath = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      <Helmet>
        <title> Baba Baidyanath Temple </title>
        <meta 
          name='description'
          content="Deoghar, a picturesque town in Jharkhand, houses important Hindu pilgrimage sites. Efforts to enhance visitor facilities ongoing since 2002."
        />
        <meta name="keywords" content="baidyanath temple,
          architectural temple hindu,
          temple redevelopment architect,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India " 
        />
        <meta name='title' content='Baba Baidyanath Temple' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
        <meta property="og:title" content="Baba Baidyanath Temple" />
          <meta property="og:description" content="Deoghar, a picturesque town in Jharkhand, houses important Hindu pilgrimage sites. Efforts to enhance visitor facilities ongoing since 2002." />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Baba Baidyanath Campus</div>
          <div className='project-add'>Deoghar, Jharkhand, India</div>

          <div className='date-area'>
            <div className='date'>2013-2014</div>
            <div className='area'>Site Area: 10 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 563 Million</div>
            <div className='client'>Client:  Baba Baidyanath Parbandhan Board, Deoghar</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: : Improvement and development of 
            infrastruture near & within the mandir premises.
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Deoghar is the District Headquarters located in the Santhal 
                Paragnas in Jharkhand state. As described in the Gazetteer, 
                the town was once a picturesque landscape, surrounded by 
                hills, lined with rivers and rivulets in the west and south, with 
                pinnacles of temples. It is one of the most important place 
                for Hindu pilgrimages. The location of one of the 12 Jyotirlingas at Baidhyanatha temple and one of 51 Shakti pithas 
                at Parvati Mandir, both located in Shri Baidhyanatha Temple 
                complex.</p>
           <p> Baba Mandir Prabandhan Board founded in 2002 has 
                worked towards developing facilities in and around the Temple Complex for improving the convenience and safety of 
                visitors.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Baba Baidyanath Campus Deoghar, Jharkhand, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default BabaBaidyanath;


