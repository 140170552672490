import { Image } from 'antd';
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-9.webp'
import image5 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-11.webp'
import image6 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-13.webp'
import image7 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-15.webp'
import image8 from '../../assets/ARCHITECTURE/ARCH- VALLABH SMARAK/1-17.webp'
import { Helmet } from 'react-helmet';




const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
]


const VallabhSmarak = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> VIJAY VALLABH JAIN SMARAK </title>
        <meta 
          name='description'
          content="Memorial for Shree Vijay Vallabh Suriji Maharaj in Delhi, part of a 20-acre complex on National Highway 1, with diverse edifices and functions."
        />
        <meta name="keywords" content="memorial architectural features,
            memorial architecture planning,
            temple architects ,
            architecture firm in gurgaon ,
            architects in gurgaon,
            architects in delhi,
            architecture firm in delhi,
            jain architecture temple " 
        />
        <meta name='title' content=' VIJAY VALLABH JAIN SMARAK' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Vijay Vallabh Jain Smarak</div>
          <div className='project-add'>New Delhi, India</div>

          <div className='date-area'>
            <div className='date'>2002-2012</div>
            <div className='area'>Site Area: 20 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 115 Million</div>
            <div className='client'>Client: Sh. Atma Vallabh Jain Smarak Shikshan Nidhi</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Masterplanning and Comprehensive Architectural Services
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The memorial dedicated to “Shree Vijay Vallabh Suriji Maharaj” (1870-1954 AD) stands within a 20-acre institutional 
                complex along National Highway no. 1 in Delhi. Originally 
                envisioned by Late Chandubhai Trivedi, our firm took over 
                the partially completed project in 1996. The memorial’s construction was finalized by adding a temple to the primary 
                structure in 2003. The complex also accommodates various 
                other edifices, including a smaller temple, a Samadhi Mandir, guest house, administrative office, Monk’s Hostel (Upashraya), senior secondary school, and nursery school. </p>
            <p>A 20-acre site adjacent to National Highway no. 1 has been 
                integrated into the Delhi Zonal Master Plan under the Land 
                Use category of PSP (Public and Semi-Public Activities).</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Vijay Vallabh Jain Smarak New Delhi, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default VallabhSmarak;


