import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- Payakapuram lake/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- Payakapuram lake/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- Payakapuram lake/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- Payakapuram lake/1-7.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
]


const PayakapuramLake = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Payakapuram Lake </title>
        <meta 
          name='description'
          content="The site, affected by urban expansion, needs restoration for water scarcity. Conservation vital for Vijayawada's water resources and wildlife revival."
        />
        <meta name="keywords" content="lake conservation,
          architecture firm in gurgaon ,
          Payakapuram Lake,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          landscape architects india,
          andhra pradesh architects
          "
        />
        <meta name='title' content='Payakapuram Lake' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio/payakapuram-lake" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Conservation of Payakapuram Lake</div>
          <div className='project-add'>Vijayawada, Andhara Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2013</div>
            <div className='area'>Site Area: 10.95 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 123 Million</div>
            <div className='client'>Client: Vijayawada Guntur Tenali Mangalagiri Urban Development Authority, Vijayawada</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Detailed Project Report 
            Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The site is a natural lowland affected by urban expansion, 
                altering its drainage pattern. Encroachments and garbage 
                dumping are evident, diminishing its water body. A local water source crucial for water scarcity mitigation, the site needs 
                restoration. The area has seen urbanization due to government-sponsored settlements. Conservation is vital to maintain such natural assets against urban pressures. Reviving 
                lowlands like this safeguards water resources in Vijayawada.</p>
            <p>The main purpose was to restore and protect Payakapuram 
                water body for water harvesting and groundwater recharge 
                and also to Integrate it into the community’s life as a regional 
                recreation area and revive local ecology to attract wildlife.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Conservation of Payakapuram Lake Vijayawada, Andhara Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default PayakapuramLake;


