import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- Civil Secretariat for Govt. of Arunachal Pradesh/1-1.1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- Civil Secretariat for Govt. of Arunachal Pradesh/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- Civil Secretariat for Govt. of Arunachal Pradesh/1-5.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
]


const Secretariat = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Civil Secretariat of Itanagar </title>
        <meta 
          name='description'
          content="Arunachal Pradesh hosted design contest for Itanagar's Civil Secretariat. 2nd Prize winner on 9-acre site near National Highway, 3 lac sq. ft. area."
        />
        <meta name="keywords" content="secretariat building design,
          secretariat building planning,
          design aspects for secretariat building,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India
          " 
        />
        <meta name='title' content='Civil Secretariat of Itanagar' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Civil Secretariat in Itanagar</div>
          <div className='project-add'>Itanagar, Arunachal Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2004</div>
            <div className='area'>Site Area: 10 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 495.3 Million</div>
            <div className='client'>Client: Government of Arunachal Pradesh</div>
            <div className='status'>Status: Proposal</div>
          </div>
          <div className='short-info'>
            Scope of Services: Complete Architecture Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The government of Arunachal Pradesh initiated an open design competition for the planned Civil Secretariat building in 
                Itanagar, the state capital. </p>
            <p>The project encompassed a sloping 9.0-acre site adjacent 
                to the National Highway, requiring a total covered area of 
                around 3.0 lac sq. ft. The entry secured the 2nd Prize.</p>
            <p>Addressing the major concerns and shaping our strategy, we 
                have focused on two key aspects: Image & Scale and Functional Aspects.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Civil Secretariat in Itanagar, Arunachal Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default Secretariat;


