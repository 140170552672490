import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- SSB SRINAGAR/1-1 RECTANGLE.webp'
// import image1 from '../../assets/LANDSCAPE/LANDS- SSB SRINAGAR/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- SSB SRINAGAR/1-5.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 2,
    image:image2,
  },
]


const SsbSrinagar = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> SSB Campus </title>
        <meta 
          name='description'
          content="Ministry of Home Affairs chose a 52.64-acre site, a Battalion HQ, for Police Academy conversion. Scenic, bordered by a highway and river. Design covers 133,505 sq. ft."
        />
        {/* <meta name="keywords" content="react, helmet, seo" /> */}
        <meta name='title' content='SASHASTRA SEEMA BAL (SSB) CAMPUS, SRINAGAR' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Sashastra SeemaBal (SSB) Campus</div>
          <div className='project-add'>Srinagar, Garhwal, India</div>

          <div className='date-area'>
            <div className='date'>2007</div>
            <div className='area'>Site Area: 52.64 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 120 Million</div>
            <div className='client'>Client:  DG, SSB, Ministry of Home Affairs.</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: : Master-planning, Comprehensive Landscape Development Plan with details
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The Ministry of Home Affairs selected a pre-existing 52.64-
                acre site, the headquarters of a Battalion, to undergo a transformation into a Police Academy for its fresh personnel. </p>
            <p>The site comprised several residential quarters and a substantial amount of temporary constructions. Nestled in a 
                scenic location, the site is bordered by a national highway on 
                one side and the Alaknanda River on the other. Our design 
                encompassed a total area of 133,505 square feet.</p>
            <p>Scope of Work includes Architectural, Urban, and Landscape Design for the transformation of a 52.64-acre Battalion 
                headquarters site into a Police Academy</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Sashastra SeemaBal (SSB) Campus Srinagar, Garhwal, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default SsbSrinagar;


