import { Link } from "react-router-dom";
import './navbar.scss'
import { Breadcrumb } from 'antd'
import logo from '../../assets/Logo.png'

import {RightOutlined} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from 'react-icons/rx'
import { motion } from 'framer-motion';



export default function Navbar(props) {
    const breadcrumbData = useSelector(state => state.breadcrumbData);
    const navbarActive = useSelector(state => state.navbarActive);
    const [toggle, setToggle] = useState(false);
    const [plus1, setPlus1] = useState(true);
    const [plusIndex, setPlusIndex] = useState(9);
    const [subplus, setSubPlus] = useState(true);


    var prevScrollpos = window.scrollY;
    window.onscroll = function() {
    var currentScrollPos = window.scrollY;
    if(currentScrollPos > 50){
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar").style.top = "0";
        } else {
            document.getElementById("navbar").style.top = "-80px";
        }
        prevScrollpos = currentScrollPos;
        }
    }


    const subMenuTitle = {
        what:[
            {
                title:'Overview',
                url:'/services',
                endPoint:{
                    content1:'Jain & Associates is here to make a difference through technology.',
                    content2: 'Leading the way in innovation for over 30 years, we build greater futures through Architecture globally.',
                    contentUrl: '/services'
                }
            },
            {
                title:'Architcture and Urbanism',
                url:'',
                endPoint:[{name:'Master Planning', url:'/bimservices/master-planning'},{name:'Landscape Architecture', url:'/bimservices/landscape-architecture'},{name:'Architecture Consultancy', url:'/bimservices/architecture-consultancy'},{name:'Zoo/Safari Design', url:'/bimservices/safari-design'},{name:'Urban Planning', url:'/bimservices/urban-planning'}]
            },
            {
                title:'Sustainibility',
                url:'',
                endPoint:[{name:'Energy Modelling', url:'/sustainibility/energy-modelling'},{name:'Daylight Modelling', url:'/sustainibility/daylight-modelling'},{name:'CFD Analysis', url:'/sustainibility/CFD-analysis'},{name:'LEED Certification', url:'/sustainibility/leed-certification'},{name:'Training and Capacity Building', url:'https://www.youtube.com/playlist?list=PLBkxQeo6T_P76F84D-Hasyl02DvJdLfXb'},{name:'GRIHA Certification', url:'/sustainibility/griha-certification'},{name:'WELL Certification', url:'/sustainibility/well-certification'}]
            },
            {
                title:'BIM Services',
                url:'',
                endPoint:[{name:'3D Modelling', url:'/bimservices/3D-modelling'},{name:'4D/5D Construction', url:'/bimservices/4D-construction'},{name:'Structural Design', url:'/bimservices/structural-design'},{name:'Clash Detection', url:'/bimservices/clash-detection'},{name:'Quantity take off', url:'/bimservices/quantity-takeoff'},{name:'Scan to BIM', url:'/bimservices/scan-to-bim'},{name:'MEP Modelling', url:'/bimservices/MEP-modelling'},{name:'Shop Drawing production', url:'/bimservices/shop-drawing-production'}]
            },
            {
                title:'Verticals',
                url:'',
                endPoint:[{name:'Architects', url:'/verticals/architects'},{name:'Structural Engineer', url:'/verticals/structural-engineer'},{name:'MEP Engineer', url:'/verticals/MEP-engineer'}, {name:'General Contractors', url:'/verticals/general-contractors'}, {name:'Sub Contractors', url:'/verticals/sub-contractors'}, {name:'Dry Wall Contractors', url:'/verticals/dry-wall-contractors'},{name:'Owners and Building', url:'/verticals/owners-and-building'}]
            },
        ],
        who:[
            {
                title:'Overview',
                url:'overview',
                endPoint:{
                    content1:'Jain & Associates is here to make a difference through technology.',
                    content2: 'Leading the way in innovation for over 30 years, we build greater futures through Architecture globally.',
                    contentUrl: '/#'
                }
            },
            {
                // title:'About us', 
                title: 'Architcture and Urbanism', //temp data
                url:'/about-us',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
            {
                title:'Leadership',
                url:'/leadership',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
            {
                title:'Awards',
                url:'/awards',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
            {
                title:'Design Process',
                url:'/design-process',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
        ],
    }

    const subMenuTitle2 = {
        what:[
            {
                title:'Architcture and Urbanism',
                url:'',
                endPoint:[{name:'Master Planning', url:'/bimservices/master-planning'},{name:'Landscape Architecture', url:'/bimservices/landscape-architecture'},{name:'Architecture Consultancy', url:'/bimservices/architecture-consultancy'},{name:'Zoo/Safari Design', url:'/bimservices/safari-design'},{name:'Urban Planning', url:'/bimservices/urban-planning'}]
            },
            {
                title:'Sustainibility',
                url:'',
                endPoint:[{name:'Energy Modelling', url:'/sustainibility/energy-modelling'},{name:'Daylight Modelling', url:'/sustainibility/daylight-modelling'},{name:'CFD Analysis', url:'/sustainibility/CFD-analysis'},{name:'LEED Certification', url:'/sustainibility/leed-certification'},{name:'Training and Capacity Building', url:'https://www.youtube.com/playlist?list=PLBkxQeo6T_P76F84D-Hasyl02DvJdLfXb'},{name:'GRIHA Certification', url:'/sustainibility/griha-certification'},{name:'WELL Certification', url:'/sustainibility/well-certification'}]
            },
            {
                title:'BIM Services',
                url:'',
                endPoint:[{name:'3D Modelling', url:'/bimservices/3D-modelling'},{name:'4D/5D Construction', url:'/bimservices/4D-construction'},{name:'Structural Design', url:'/bimservices/structural-design'},{name:'Structural Analysis', url:'/bimservices/structural-analysis'},{name:'Clash Detection', url:'/bimservices/clash-detection'},{name:'Quantity take off', url:'/bimservices/quantity-takeoff'},{name:'Scan to BIM', url:'/bimservices/scan-to-bim'},{name:'MEP Modelling', url:'/bimservices/MEP-modelling'},{name:'Shop Drawing production', url:'/bimservices/shop-drawing-production'}]
            },
            {
                title:'Verticals',
                url:'',
                endPoint:[{name:'Architects', url:'/verticals/architects'},{name:'Structural Engineer', url:'/verticals/structural-engineer'},{name:'MEP Engineer', url:'/verticals/MEP-engineer'}, {name:'General Contractors', url:'/verticals/general-contractors'}, {name:'Sub Contractors', url:'/verticals/sub-contractors'}, {name:'Dry Wall Contractors', url:'/verticals/dry-wall-contractors'},{name:'Owners and Building', url:'/verticals/owners-and-building'}]
            },
        ],
        who:[
            {
                title:'About us',
                url:'/about-us',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
            {
                title:'Leadership',
                url:'/leadership',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
            {
                title:'Awards',
                url:'/awards',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
            {
                title:'Design Process',
                url:'/design-process',
                endPoint:[{name:'endpoint'},{name:'endpoint'}]
            },
        ],
    }

  return (
    <>
    <div className='navbar' id='navbar'>
        <div className='navbar1'>
            <div className='menu'>
                <div className='logo'><a className="link" href='/'><img src={logo} alt='J&A Logo'/></a></div>
                <div className='section' ><a className="link" href='/what-we-do'><span>What we do</span></a>
                    <Layout subMenu={subMenuTitle['what']}/>
                </div>
                <div className='section'><a className="link" href='/who-we-are'><span>Who we are</span></a>
                    {/* <Layout subMenu={subMenuTitle['who']}/> */}
                </div>
                <div className='section'><span><Link className="link" to='/portfolio'>Portfolio</Link></span></div>
                <div className='section'><span><a className="link" target="__blank" href='https://blogs.jain-associates.com/'>Media</a></span></div>
            </div>
            <div className='contact'>
                {/* Contact Us */}
                <Link className="link" to='/contact'>Contact Us</Link>
            </div>
        </div>


        <div className="navbar-links">
            <div className='logo'><a className="link" href='/'><img src={logo} alt='J&A Logo'/></a></div>
            <div className='mob-menu'>
                <FiMenu onClick={() => setToggle(true)}/>

                {toggle && (
                    <motion.div
                            whileInView={{ x: [300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                        >
                            {/* cross button */}
                            <RxCross2 onClick={() => setToggle(false)} /> 
                            <ul>
                                <li key='what'>
                                    <Link to='/what-we-do' onClick={() => setToggle(false)}> What we Do </Link> 
                                    { (plus1)? <a className="plus" onClick={() => setPlus1(false)}> + </a> : <a className="plus" onClick={() => {setPlus1(true); setSubPlus(false)}}> - </a> } 
                                    
                                    {(!plus1) &&  
                                        (<ul>

                                            {subMenuTitle2['what'].map((item, index) => (
                                                <li key={item.title}>
                                                    <Link to={item.url} onClick={() => setToggle(false)}> { item.title } </Link> 
                                                    { (subplus && index===plusIndex)? <a className="plus" onClick={() => {setSubPlus(false); setPlusIndex(index)}}> - </a> : <a className="plus" onClick={() => {setSubPlus(true); setPlusIndex(index)}}> + </a> } 

                                                    {
                                                        (subplus && index===plusIndex) && 

                                                        (
                                                            <ul>
                                                                {item.endPoint.map((data) => (
                                                                    <li key={data.title}>
                                                                        <Link to={data.url} onClick={() => setToggle(false)}> { data.name } </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                    }
                                                </li>
                                            ))}
                                        </ul>)
                                    }

                                </li>

                                <li key='who'>
                                    <Link to='/who-we-are' onClick={() => setToggle(false)}> Who we are </Link> 
                                    {/* { (plus2)? <a className="plus" onClick={() => setPlus2(false)}> + </a> : <a className="plus" onClick={() => setPlus2(true)}> - </a> } 
                                    
                                    {(!plus2) &&  
                                        (<ul>

                                            {subMenuTitle2['who'].map((item, index) => (
                                                <li>
                                                    <Link to={item.url} onClick={() => setToggle(false)}> { item.title } </Link>
                                                    { (subplus2 && index===plusIndex2)? <a className="plus" onClick={() => {setSubPlus2(false); setPlusIndex2(index)}}> - </a> : <a className="plus" onClick={() => {setSubPlus2(true); setPlusIndex2(index)}}> + </a> } 

                                                    {
                                                        (subplus2 && index===plusIndex2) && 

                                                        (
                                                            <ul>
                                                                {item.endPoint.map((data) => (
                                                                    <li key={data.title}>
                                                                        <Link to={data.url} onClick={() => setToggle(false)}> { data.name } </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                    }
                                                    
                                                </li>
                                            ))}
                                        </ul>)
                                    } */}
                                </li>
                                <li key='portfolio'>
                                    <Link to='/portfolio' onClick={() => setToggle(false)}> portfolio </Link> 
                                </li>
                                <li key='media'>
                                    <a href="https://blogs.jain-associates.com/" target="__blank" onClick={() => setToggle(false)}> media </a> 
                                </li>
                                <li key='contact'>
                                    <Link to='/contact' onClick={() => setToggle(false)}> contact us </Link> 
                                </li>
                            </ul>
                    </motion.div>
                    )
                }
            </div>
        </div>


        <div className='navbar2 '>
            <Breadcrumb className={`navbar2-h1 ${props.navbar2Active ? 'navbarActive' : 'navbarInactive'}`}
                items={breadcrumbData}
            />
        </div>
    </div>
    </>
  )
}


function Layout(props){
    const [hoverActive, setHoverActive] = useState(0)

    return (
        <div className='layout' onMouseLeave={() => setHoverActive(0)} >
            <div className='submenu'>
            {props.subMenu.map((data, index) => (
                <>
                <div className='submenu_content' key={index} onMouseEnter={() => setHoverActive(index)} onMouseLeave={() => setHoverActive(index)} style={(index===hoverActive)?{backgroundColor:"#5cb800", opacity:"1",borderBottom:"1.5px solid whitesmoke"} : {backgroundColor:"#000000"}}>
                    <Link className="link" to={data.url}>
                        <div className='submenu_head'>
                            <div >{data.title}</div><RightOutlined />
                        </div>
                    </Link>
                    {index === hoverActive && (
                        <Desc data = {data.endPoint} />
                    )}
                </div>
                </>
            ))}
            </div>
        </div>
    )
}


function Desc(props){
    const {data, style} = props
    return(
        <>
        {(data.content1)
        // For Overview 
            ?  <div className='desc'> <div className="overview"><h2>{data.content1}</h2><p>{data.content2}</p><Link className="o-link" to={data.contentUrl}>Discover More</Link></div> </div>
            : <div className='desc' style={style}>
            {/* for others link  */}
            <ul className='desc-content'>
                {data.map((subdata, index) => (
                <li key={index}>
                    <Link to={subdata.url}> {subdata.name} </Link>
                </li>
                ))}
            </ul>
        </div>
        }
        </>
    )
}

