import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- SHEEL KUNJ/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- SHEEL KUNJ/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- SHEEL KUNJ/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- SHEEL KUNJ/1-7.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
]


const SheelKunj = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Landscaping of Sheel Kunj Enclave </title>
        <meta 
          name='description'
          content="Sheel Kunj Enclave: harmonious urban-nature integration. Green spaces, modern amenities, approved by Meerut Development Authority."
        />
        <meta name="keywords" content="landscape features ,
          landscape architects,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India
          " 
        />
        <meta name='title' content='Landscaping of Sheel Kunj Enclave' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Landscaping of Sheel Kunj Enclave</div>
          <div className='project-add'>Meerut, Uttar Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2017</div>
            <div className='area'>Site Area: 6.78 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: Not Known</div>
            <div className='client'>Client: Indus Valley Project Developers</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Landscape Design
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Sheel Kunj Enclave stands as a meticulously designed residential community, aiming to harmoniously integrate nature 
                within an urban setting. </p>
            <p>Abundant green spaces, modern civic facilities, and advanced infrastructure converge to offer a truly delightful living 
                experience. The project has approval from the Meerut Development Authority</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Landscaping of Sheel Kunj Enclave Meerut, Uttar Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default SheelKunj;


