import { Image } from 'antd';
import './project.scss'
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-1.1 rectangle .webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-9.webp'
import image5 from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-11.webp'
import image6 from '../../assets/ARCHITECTURE/ARCH- RESIDENTIAL COMPLEX FOR MUNICIPAL CORPORATION, SEC-41, GURGAON/1-13.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
]


const MuncipalCorp = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Residential Complex for Municipal Corporation, Gurgaon </title>
        <meta 
          name='description'
          content='Design emphasizes simplicity, revenue generation, energy efficiency, sustainability, community integration, and recreational facilities.'
        />
        <meta name="keywords" content="residential architecture,
          Residential Complex,
          residential planning ,
          sustainable architects,
          Residential firm,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi, " 
        />
        <meta name='title' content='Residential Complex for Municipal Corporation, Gurgaon' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Residential Complex for MCG</div>
          <div className='project-add'>Gurgaon, Haryana, India</div>

          <div className='date-area'>
            <div className='date'>2015</div>
            <div className='area'>Site Area: 11.24 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: Undisclosed</div>
            <div className='client'>Client:  Municipal Corporation, Gurgaon</div>
            <div className='status'>Status: Proposal</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Conceptual Design
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The design highlights encompass simplicity, clear zoning, and strong accessibility. Innovative revenue-generating features include a commercial building, multipurpose hall, and convenient shops. 
                Energy efficiency is achieved through strategic aperture orientation, shading, and insulation. Green 
                spaces and community integration are prioritized, 
                and ample parking and landscaping are provided. </p>
            <p>Sustainability features include a sewage treatment plant, solar water heating, and lighting. The design also incorporates 
                a club with a range of facilities for recreation.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Residential Complex for MCG Gurgaon, Haryana, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default MuncipalCorp;


