import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'
import { Helmet } from 'react-helmet';


// import projectImg from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-1 rectangle.webp'
// import image1 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-3.webp'
// import image2 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-5.webp'
// import image4 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-9.webp'
// import image5 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-11.webp'
// import image6 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-13.webp'
// import image7 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-15.webp'
// import image8 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-17.webp'
// import image9 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-19.webp'
// import image10 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-21.webp'
// import image11 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-23.webp'
// import image12 from '../../assets/URBAN/URBAN- MEGHALAYA ECOTOURISM/1-25.webp'



const projectImages = [
  {
    id: 1,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-3.webp',
  },
  {
    id: 2,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-5.webp',
  },
  {
    id: 4,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-9.webp',
  },
  {
    id: 5,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-11.webp',
  },
  {
    id: 6,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-13.webp',
  },
  {
    id: 7,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-15.webp',
  },
  {
    id: 8,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-17.webp',
  },
  {
    id: 9,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-19.webp',
  },
  {
    id: 10,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-21.webp',
  },
  {
    id: 11,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-23.webp',
  },
  {
    id: 12,
    image:'https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-25.webp',
  },
]


const MeghalayaEcotourism = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Eco-Tourism Development and Conserving Natural Resources in Meghalaya  </title>
        <meta 
          name='description'
          content='Seeking tourism growth, sustainability. Enhance eco-infrastructure, empower locals for socio-economic well-being.'
        />
        <meta name="keywords" content="Ecotourism architecture,
          Ecotourism  Meghalaya,
          Landscape Architects India,
          Sustainable architecture,
          Meghalaya Tourism,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architecture firm in delhi
          " 
        />
        <meta name='title' content='Eco-Tourism Development and Conserving Natural Resources in Meghalaya ' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Eco-tourism Development for Empowering Rural Youth</div>
          <div className='project-add'>Smit - Mawkynrew, Meghalaya, India</div>

          <div className='date-area'>
            <div className='date'>2022</div>
            <div className='area'>Site Area: 85498.5 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 1.43 Billion</div>
            <div className='client'>Client: Meghalayan Age Ltd. funder by ADB</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Masterplanning and DPR
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Smit-Mawkynrew cluster is one of least visited region except 
                Laitlum. The main obstacle was to discover landscapes and 
                cultural assets that could be transformed into profitable tourism opportunities for both local communities and the government. These endeavours must be approached with a focus 
                on environmental sustainability.</p>
            <p>
              The aim is to tap into tourism potential within the cluster by fostering and enhancing eco-tourism infrastructure. 
              This includes creating diverse income sources for local 
              youth, improving connections with neighbouring clusters, 
              towns, and villages, and boosting livelihoods and socio-economic well-being through tourism opportunities
            </p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={`https://files.jain-associates.com/URBAN/URBAN-%20MEGHALAYA%20ECOTOURISM/1-1%20rectangle.webp`} alt='Eco-tourism Development for Empowering Rural Youth Smit - Mawkynrew, Meghalaya, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default MeghalayaEcotourism;


