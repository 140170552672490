import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- baroda/1-1.1 FRONT IMAGE .webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- baroda/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- baroda/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- baroda/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH- baroda/1-9.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  }
]


const Baroda = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Northern Railway Headquarters </title>
        <meta 
          name='description'
          content="Baroda House, a heritage property in Central Vista, evolved with government needs, seeking efficient, modern adaptations by Northern Railways."
        />
        <meta name="keywords" content="baroda house,
          new delhi,
          heritage architecture,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India,
          " 
        />
        <meta name='title' content="Northern Railway Headquarters" />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
        <meta property="og:title" content="Northern Railway Headquarters" />
        <meta property="og:description" content="Baroda House, a heritage property in Central Vista, evolved with government needs, seeking efficient, modern adaptations by Northern Railways." />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Baroda House</div>
          <div className='project-add'>Baroda House New Delhi, India</div>

          <div className='date-area'>
            <div className='date'>2012</div>
            <div className='area'>Site Area: 12.47 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 430 Million</div>
            <div className='client'>Client:  Northern Railways, New Delhi</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
            Scope of Services: Complete Architectural Consultancy, Landscape Design, MEP and Structural
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Baroda house is a 2085 sqm. site with landmark Heritage 
                property. It influences and adds to Urban and Landscape design concept of the Central Vista, which in turn is seat of the 
                Indian Government.</p>
            <p>To make plot usable, after independence, such properties 
                saw addition of office blocks. As per the needs of the times, 
                new blocks, structures (both permanent and temporary) were 
                added and modified. With onset of new Master plan and 
                constitution of many statutory authorities, government aimed 
                to do away with adhoc approach and aimed to streamline 
                all future constructions. They broadly consider its context, 
                modern needs and efficiency standards.</p>
            <p>Northern Railways aims to under take new construction and 
              regularization of irregular structures with necessary additions 
              and alterations.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Baroda House New Delhi, India'/></div>
        </div>
      </div>
      
      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default Baroda;


