import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- VIZAG ZOO/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- VIZAG ZOO/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- VIZAG ZOO/1-5.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
]


const VizagZoo = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Visakhapatnam Zoological Park </title>
        <meta 
          name='description'
          content="625 forest acres with 21.33 coastal acres planned. Challenges include hills, limited space. Revitalization includes irrigation, better facilities, visitor upgrades.          "
        />
        <meta name="keywords" content="zoo designers ,
          zoo planning,
          zoo architecture,
          zoo in visakhapatnam,
          landscape architect india,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi
          " 
        />
        <meta name='title' content='Visakhapatnam Zoological Park' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Indira Gandhi Zoological Park</div>
          <div className='project-add'>Visakhapatnam, Andhra Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2010</div>
            <div className='area'>Site Area: 646.33 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 370.94 Million</div>
            <div className='client'>Client: CCF, Zoological Parks, Andhra Pradesh.</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
            Scope of Services: Masterplan layout and Report
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>
              Established in 1977, the Indira Gandhi Zoological Park in 
              Visakhapatnam spans 625 acres of forest land. An additional 21.33 acres of coastal land is planned for incorporation, 
              separated by a public road. This coastal area, known as the 
              ‘Marine Land Complex’, aims to enhance visibility. Despite 
              the apparent size, the site’s topography presents challenges 
              with steep hills and limited usable space.</p>
            <p>
              Our task involves revitalizing the landscape, introducing irrigation for sustained upkeep, enhancing animal facilities, and 
              upgrading visitor amenities, all while addressing visual and 
              functional aspects.
            </p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Indira Gandhi Zoological Park Visakhapatnam, Andhra Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default VizagZoo;


