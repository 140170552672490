import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/URBAN/URBAN- GMDA/1-1 RECTANGLE.webp'
import image1 from '../../assets/URBAN/URBAN- GMDA/1-3.webp'
import image2 from '../../assets/URBAN/URBAN- GMDA/1-5.webp'
import image3 from '../../assets/URBAN/URBAN- GMDA/1-7.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  }
]


const Gmda = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Streetscaping of Roads in Gurgaon </title>
        <meta 
          name='description'
          content='Vyapar Kendra and Hamilton Court Roads, converted to unidirectional due to construction, face heavy traffic causing congestion and pollution.'
        />
        <meta name="keywords" content="Streetscaping planning,
          streetscaping and architecture,
          landscape architects india,
          landscape architects in delhi ncr,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi
          " 
        />
        <meta name='title' content='REDEVELOPMENT OF HAMILTON & VYAPAR KENDRA ROAD, GURUGRAM' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Redevelopment of Hamilton & Vyapar Kendra Road</div>
          <div className='project-add'>Gurugram, Haryana, India</div>

          <div className='date-area'>
            <div className='date'>2023</div>
            <div className='area'>Site Area: 4.2km Road</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 620 Million</div>
            <div className='client'>Client: Gurugram Metropolitan Development Authority (GMDA), Haryana</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services:  Detailed Project Report
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Vyapar Kendra Road and Hamilton Court Road serve as sector-dividing roads with a V3 classification, boasting a 30-meter-wide right of way. The two war roads in September 2011, 
                due to impending construction on Golf Course Road, these 
                V3 roads were converted into unidirectional traffic routes.</p>
            <p>Over the past decade, urban densification has led to significantly increased traffic on roads. Consequently, residents of 
                adjacent areas like Shushant Lok Phase-I, Hamilton Court, 
                Sectors 27, 28, 43, and DLF Phase-IV have been compelled 
                to take longer routes.</p>
            <p>This situation has contributed to severe traffic congestion on 
                colony roads, particularly during peak hours, resulting in substantial pollution levels.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Redevelopment of Hamilton & Vyapar Kendra Road Gurugram, Haryana, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default Gmda;


