import { Image } from 'antd';
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-1 RECTANGLE.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-9.webp'
import image5 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-11.webp'
import image6 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-13.webp'
import image7 from '../../assets/ARCHITECTURE/ARCH- PCMC ZOO/1-15.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
]


const PcmcZoo = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Redevelopment of Pimpri Zoo </title>
        <meta 
          name='description'
          content="Pimpri Zoo, established in 1990, aimed for transformation in 2008. Design plan shapes its essence, goals, and future direction."
        />
        <meta name="keywords" content="zoo architecture ,
          zoo planning,
          design elements for zoo,
          incorporation of different types elements for zoo planning ,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India,
          "
        />
        <meta name='title' content='Redevelopment of Pimpri Zoo' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Redevelopment of Pimpri Zoo</div>
          <div className='project-add'>Pimpri, Pune, Maharashtra, India</div>

          <div className='date-area'>
            <div className='date'>2015</div>
            <div className='area'>Site Area: 7.05 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 200 Million</div>
            <div className='client'>Client: PCMC, Pune, Maharashtra</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Masterplanning, DPR & Tender Document
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>The ‘Snake Park,’ established on a 7-acre MIDC plot within a residential area, was inaugurated in January 1990. In 
                2008, the operator, Pimpri Chinchwad Municipal Corporation 
                (PCMC), aimed to transform it into the “best small zoo” in the 
                country. Consequently, we were commissioned to develop a 
                comprehensive design plan for the zoo.</p>
            <p>The Pimpri Zoo Master Plan goes beyond physical layout; it 
                delves into the zoo’s essence and future prospects. It shapes 
                the purpose of the zoo, encompassing its goals, missions, 
                and functions. At its core, the plan aligns with the zoo’s strategic direction</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Redevelopment of Pimpri Zoo, Pune, Maharashtra, India'/></div>
        </div>
      </div>


      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default PcmcZoo;


