import { Image } from 'antd';
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';

import projectImg from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-1 rectangle.webp'
import image1 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-3.webp'
import image2 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-5.webp'
import image3 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-7.webp'
import image4 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-9.webp'
import image5 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-11.webp'
import image6 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-13.webp'
import image7 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-15.webp'
import image8 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-17.webp'
import image9 from '../../assets/ARCHITECTURE/ARCH-shopping complex cum park. space, jammu/1-19.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
  {
    id: 5,
    image:image5,
  },
  {
    id: 6,
    image:image6,
  },
  {
    id: 7,
    image:image7,
  },
  {
    id: 8,
    image:image8,
  },
  {
    id: 9,
    image:image9,
  },
]


const ShoppingComplex = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
     <Helmet>
        <title> Jammu Shopping Complex </title>
        <meta 
          name='description'
          content="Jammu's bustling heart hosts a fusion complex, integrating shopping and parking, aiming to revitalize congested areas."
        />
        <meta name="keywords" content="mall architecture ,
          Jammu Shopping Complex ,
          jammu architects,
          jammu mall,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          architecture firm India,
          " 
        />
        <meta name='title' content='Jammu Shopping Complex with parking space' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Shopping Mall cum Parking Space</div>
          <div className='project-add'>Parade Road, Jammu, India</div>

          <div className='date-area'>
            <div className='date'>2021</div>
            <div className='area'>Site Area: 0.80 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 200 Million</div>
            <div className='client'>Client: Jammu Development Authority</div>
            <div className='status'>Status: Ongoing</div>
          </div>
          <div className='short-info'>
            Scope of Services: Comprehnsive Architecture Consultancy
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Situated in the bustling heart of Jammu, this venture represents a fusion of a shopping complex and parking facility. 
                Its significance is intended to surpass local boundaries, offering a revitalization of the congested shops and parking areas.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Shopping Mall cum Parking Space Parade Road, Jammu, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                projectImages.map((data, index) => (
                    <Image 
                        className='gallery-content'
                        width={250}
                        src={data.image}
                        key={index}
                    />
                ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}


export default ShoppingComplex;


