import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- WOODS RESORT/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- WOODS RESORT/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- WOODS RESORT/1-5.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  }
]


const WoodsResort = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Landmark Woods Resort </title>
        <meta 
          name='description'
          content="A comprehensive resort vision caters to diverse tourist preferences.          "
        />
        <meta name="keywords" content="landscape architects in delhi ncr,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          landscape architect India,
          architecture firm India
          " 
        />
        <meta name='title' content='Landmark Woods Resort' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Landmark Woods Resort</div>
          <div className='project-add'>Kaziranga, Assam, India</div>

          <div className='date-area'>
            <div className='date'>2008</div>
            <div className='area'>Site Area: 11.15 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 120 Million</div>
            <div className='client'>Client: M/S Landmark Hotels</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Masterplanning & Landscape Detail Services
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>In the initial stages, ‘Landmark Hotels’ developed two guest 
                room wings along with an access road. Recognizing the demand for a holistic approach, they initiated a thorough site 
                survey, leading to the formulation of a comprehensive resort 
                vision that caters to a diverse range of tourist preferences 
                and needs. </p>
            <p>This vision aims to provide an enriching experience for all 
                segments of tourists visiting the resort.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Landmark Woods Resort Kaziranga, Assam, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default WoodsResort;


