import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- KANGRA ZOO/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- KANGRA ZOO/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- KANGRA ZOO/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- KANGRA ZOO/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- KANGRA ZOO/1-9.webp'
import { Helmet } from 'react-helmet';



const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  },
//   {
//     id: 5,
//     image:image5,
//   },
]


const KangraZoo = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      
      <Helmet>
        <title> Kangra Zoological Park </title>
        <meta 
          name='description'
          content="Kangra's spiritual heritage merges with upcoming Zoo & Safari, promoting eco-tourism, conservation, and wildlife education."
        />
        <meta name="keywords" content="zoo design ,
          zoo planning,
          architectural features for zoo,
          zoo designing firms in delhi ncr,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          landscape architect india
          " 
        />
        <meta name='title' content='Kangra Zoological Park' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Kangra Zoological Park (<i>Durgesharanya</i>)</div>
          <div className='project-add'>Kangra, Himachal Pradesh, India</div>

          <div className='date-area'>
            <div className='date'>2023</div>
            <div className='area'>Site Area: 425 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 4500 Million</div>
            <div className='client'>Client: Divisional Forest Officer, Hamirpur, H.P</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
                Scope of Services: Master plan (Layout & Report)
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>Kangra, an ancient abode of the Mother Goddess, hosts 
                revered shrines like Jwala ji and Chintpurni. The forthcoming Zoo and Safari experiences can connect wildlife and 
                eco-tourism with the spiritual and scriptural essence of Mahabharata and Ramayana</p>
            <p>Our mission is to instill conservation values in visitors and 
                inspire them. Our objectives include enhancing animal welfare, leveraging technology for better visitor experiences, and 
                building environmentally friendly infrastructure. </p>
            <p>We aim to cultivate skilled teams for advanced animal management, rescue native species, participate in wildlife conservation programs, and engage in educational outreach.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Kangra Zoological Park Durgesharanya Kangra, Himachal Pradesh, India'/></div>
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default KangraZoo;


