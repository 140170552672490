import { Image } from 'antd';

import Navbar from '../navbar/Navbar';
import FooterUp from '../footer/FooterUp';
import Footer from '../footer/Footer'


import projectImg from '../../assets/LANDSCAPE/LANDS- DELHI ZOO/1-1 RECTANGLE.webp'
import image1 from '../../assets/LANDSCAPE/LANDS- DELHI ZOO/1-3.webp'
import image2 from '../../assets/LANDSCAPE/LANDS- DELHI ZOO/1-5.webp'
import image3 from '../../assets/LANDSCAPE/LANDS- DELHI ZOO/1-7.webp'
import image4 from '../../assets/LANDSCAPE/LANDS- DELHI ZOO/1-9.webp'
import { Helmet } from 'react-helmet';


const projectImages = [
  {
    id: 1,
    image:image1,
  },
  {
    id: 2,
    image:image2,
  },
  {
    id: 3,
    image:image3,
  },
  {
    id: 4,
    image:image4,
  }
]


const DelhiZoo = () => {
  return (
    <><Navbar />
    <div className='project'>
      
      <Helmet>
        <title> Delhi Zoo </title>
        <meta 
          name='description'
          content='NZP, a vital tourist spot in Delhi, plans upgrades via Master Plan 2012–2031 to enhance facilities, image, and management.'
        />
        <meta name="keywords" content="delhi zoo,
          zoo designing and planning ,
          zoo architecture	,
          zoo planners & designers,
          architecture firm in gurgaon ,
          architects in gurgaon,
          architects in delhi,
          architecture firm in delhi,
          landscape architects india
          " 
        />
        <meta name='title' content='Delhi Zoo' />
        <meta name='robots' content='index, follow'/>
        <link rel="canonical" href="https://jain-associates.com/portfolio" />
      </Helmet>
      
      <div className='top-container'>
        <div className='project-description'>
          <div className='project-name'>Delhi Zoo</div>
          <div className='project-add'>New Delhi, India</div>

          <div className='date-area'>
            <div className='date'>2010-12</div>
            <div className='area'>Site Area: 188.6 Acres</div>
          </div>

          <div className='project-info'>
            <div className='cost'>Cost of Project: INR 1.59 Billion</div>
            <div className='client'>Client: Ministry of Forest and Environment, New Delhi</div>
            <div className='status'>Status: Completed</div>
          </div>
          <div className='short-info'>
            Scope of Services: Masterplan with all Designs, Layout, 
              Operation plans, Business plan and Cost estimates
          </div>
          <div className='line'></div>

          <div className='description'>
            <p>NZP has 94 species with over 926 inmates (excluding captive pond birds) and 72 enclosures. With footfalls of over two 
                million and its central location in the national capital makes 
                it important tourist destination. Rising aspirations of society 
                demands word class zoo. Being only such facility, owned 
                and operated by Indian government, it needs to project 
                appropriate image also. Under this backdrop, proposed 
                Master plan aims to upgrade all facilities, infrastructure and 
                management structure. Creation of ‘NZP Foundation’ is proposed. </p>
            <p>Proposed Master plan from 2012–2031 considers interventions in land under possession only. However, its surrounding 
              uses and linkages are also considered.</p>
          </div>
        </div>
        <div className='project-image'>
          <div><img src={projectImg} alt='Delhi Zoo New Delhi, India'/></div>
        </div>
      </div>
      
      <div className='gallery'>
        <div className='gallery-preview'>
        <Image.PreviewGroup >
              {
                  projectImages.map((data, index) => (
                      <Image 
                          className='gallery-content'
                          width={250}
                          src={data.image}
                          key={index}
                      />
                  ))
              }
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    <FooterUp/>
    <Footer/>
    </>
  )
}

export default DelhiZoo;


